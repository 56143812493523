import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Div from "../../../packages/Div/src";
import Container from "../../../packages/Container/src";
import { TabBar } from "../../../packages/Tabs/src";

var SecondaryNavigationPatttern = function SecondaryNavigationPatttern() {
  return React.createElement(PatternLayout, null, React.createElement(Div, {
    bg: "grey.50",
    pt: "4rem"
  }, React.createElement(Container, null, React.createElement(TabBar, {
    borderColor: "transparent",
    height: "3.5rem",
    color: "grey.500",
    activeTextColor: "primary",
    activeColor: "primary",
    items: [{
      key: 0,
      label: "About Datastory"
    }, {
      key: 1,
      label: "Team"
    }, {
      key: 2,
      label: "Volunteer"
    }, {
      key: 3,
      label: "Partners"
    }, {
      key: 4,
      label: "Features"
    }, {
      key: 5,
      label: "Services"
    }]
  }))));
};

export default SecondaryNavigationPatttern;